<template>
  <fault-manage :typePage="typePage"></fault-manage>
</template>

<script>
import FaultManage from "@/views/modules/collection/business/fault/faultManage.vue";

export default {
    name: "faultAuditing",
    components: {FaultManage},
    data(){
        return{
            typePage:'faultAuditing',
        }
    },
}
</script>

<style scoped>

</style>